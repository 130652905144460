// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---docs-api-reference-md": () => import("./../../../../docs/api-reference.md" /* webpackChunkName: "component---docs-api-reference-md" */),
  "component---docs-installation-md": () => import("./../../../../docs/installation.md" /* webpackChunkName: "component---docs-installation-md" */),
  "component---docs-introduction-md": () => import("./../../../../docs/introduction.md" /* webpackChunkName: "component---docs-introduction-md" */),
  "component---docs-usage-advanced-hooks-md": () => import("./../../../../docs/usage/advanced-hooks.md" /* webpackChunkName: "component---docs-usage-advanced-hooks-md" */),
  "component---docs-usage-basic-hooks-md": () => import("./../../../../docs/usage/basic-hooks.md" /* webpackChunkName: "component---docs-usage-basic-hooks-md" */),
  "component---docs-usage-ssr-md": () => import("./../../../../docs/usage/ssr.md" /* webpackChunkName: "component---docs-usage-ssr-md" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

